@import "./common";

.appContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    padding-top: $layoutNavigationHeight;

    > * {
        flex-shrink: 0;
    }

    > div, > main {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        > * {
            flex-shrink: 0;
        }
    }
}

.footer {
    background-color: #1c1c1c;
    color: white;
    text-align: center;
    padding: 5px;
}