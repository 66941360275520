@import "./common";

* {
  box-sizing: border-box;
}

html, body, #root {
  min-height: 100vh;
  margin: 0;
  padding: 0;

  font-family: $fontFamily;
}

body {
  @media(prefers-color-scheme: dark) {
    background-color: #0c0c0c;
    color: white;
  }
}

a {
  color: $colorAccent;
  text-decoration: underline dotted currentColor;
}